#root, body, html, .container {
    height: 100%;

}

body {
    background: rgb(0,82,152);
    background: linear-gradient(90deg, rgba(0,82,152,1) 0%, rgba(0,107,177,0.9738095921962535) 48%, rgba(0,107,177,1) 100%);
}

.forgot-pass-text {
    font-size: 0.9rem;
}

.forgot-pass-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.registrarse-text {
    margin-top: 20px;
    color: dodgerblue;
    font-size: 0.9rem;
}

.registrarse-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.customCard {
    width: 50%;
}

@media (max-width: 768px) {
    .customCard {
        width: 100%;
    }
}